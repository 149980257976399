document.addEventListener('DOMContentLoaded', function () {
  let organization = document.getElementById('user_organization')
  if (organization === null) {
    organization = document.getElementById('booking_organization')
    if (organization === null ) {
      // このプログラムの対象外なので終わり
      return
    }
  }
  let f0 = document.getElementById('user_organization_form_0')
  let f1 = document.getElementById('user_organization_form_1')
  let f2 = document.getElementById('user_organization_form_2')
  if (f0 === null) {
    f0 = document.getElementById('booking_organization_form_0')
    f1 = document.getElementById('booking_organization_form_1')
    f2 = document.getElementById('booking_organization_form_2')
    if (f0 === null) {
      // このプログラムの対象外なので終わり
      return
    }

    f0.addEventListener('change', onChange)
    f1.addEventListener('change', onChange)
    f2.addEventListener('change', onChange)
    onChange()
  }

  organization.addEventListener('input', onInput)
  onInput()

  function onInput() {
    const text = organization.value
    const regex = new RegExp('(株式会社|有限会社|合資会社|合同会社|合名会社|（株）|（有）|（資）|（同）|（名）|\\(株\\)|\\(有\\)|\\(資\\)|\\(同\\)|\\(名\\))|㈱|㈲')
    if (regex.test(text)) {
      f1.checked = true
      f0.disabled = true
      f1.disabled = true
      f2.disabled = true
      // console.log(text)
    } else {
      f0.disabled = false
      f1.disabled = false
      f2.disabled = false
    }
    onChange()
  }

  function onChange() {
    const form = document.getElementById('booking_form')
    const radioList = form.elements['booking[organization_form]']
    const commercialUse = document.getElementById('booking_commercial_use')
    if (radioList.value == 1) {
      commercialUse.checked = true
      commercialUse.disabled = true
    } else {
      commercialUse.disabled = false
    }
  }
})

